import React, { ReactNode } from "react";
import { AiOutlineClose } from "react-icons/ai"; // Import close icon from react-icons

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isVisible, onClose, children }) => {
  if (!isVisible) return null;

  // Typing the event correctly
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Close the modal if the click is outside the modal content
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-40"
        onClick={handleBackdropClick}
      >
        {/* Modal Content */}
        <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-md w-full m-auto top-1/4">
          {/* Close Button (Top-right) */}
          <button
            onClick={onClose}
            title="Close"
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 transition"
          >
            <AiOutlineClose size={24} />
          </button>

          {/* Modal Content */}
          {children}
        </div>
      </div>
    </>
  );
};

export default Modal;
