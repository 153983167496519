import React, { useState } from "react";
import { FiEye } from "react-icons/fi";
import Modal from "./Modal"; // Assume you have a Modal component

interface RightSidebarProps {
  D3Content: string;
  D4Content: string;
  D5Content: string;
  D7Content: string;
}

const RightSidebar: React.FC<RightSidebarProps> = ({
  D3Content,
  D4Content,
  D5Content,
  D7Content,
}) => {
  const titles = [
    "D3: Containment actions",
    "D4: Root Cause",
    "D5: Corrective actions",
    "D7: Perspective actions",
  ];

  const descriptions = [D3Content, D4Content, D5Content, D7Content];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentDescription, setCurrentDescription] = useState("");

  const openModal = (description: React.SetStateAction<string>) => {
    setCurrentDescription(description);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="w-1/4 bg-white p-4 border-l border-gray-300">
      <div className="space-y-4">
        {titles.map((title, index) => (
          <div key={index} className="space-y-2">
            <div className="flex justify-between items-center">
              <div className="bg-black text-white w-8 h-8 flex items-center justify-center rounded">{`D${
                index + 3
              }`}</div>
              <span className="text-gray-800 ml-4">{title}</span>
              <button
                onClick={() => openModal(descriptions[index])}
                className="ml-auto text-gray-500 hover:text-gray-700"
                title="View Description"
              >
                <FiEye size={20} />
              </button>
            </div>
            <div className="max-h-24 overflow-y-auto p-3 border border-gray-300 rounded-lg bg-gray-50">
              <p className="text-sm text-gray-600">{descriptions[index]}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Modal for Description */}
      <Modal isVisible={isModalVisible} onClose={closeModal}>
        <h2 className="text-xl font-semibold mb-4">Description</h2>
        <p className="text-gray-700">{currentDescription}</p>
      </Modal>
    </div>
  );
};

export default RightSidebar;
