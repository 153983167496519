import React, { useState } from "react";

const SetupWizard = () => {
  const [step, setStep] = useState(1);
  const maxSteps = 4; // Define total number of steps
  const [formData, setFormData] = useState({
    organizationDescription: "",
    departmentDescription: "",
    parts: {
      wheel: "",
      doorPanel: "",
      seats: "",
      cockpit: "",
    },
    acronyms: "",
  });

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePartChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      parts: {
        ...prevData.parts,
        [name]: value,
      },
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Simulate API submission here
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-blue-500">
      <div className="w-full max-w-4xl bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-semibold text-center mb-6">
          Setup Wizard
        </h1>
        <form onSubmit={handleSubmit}>
          {/* Step 1: Organization Overview */}
          {step === 1 && (
            <div>
              <h2 className="text-xl font-semibold mb-4 text-center">
                What does your organization do?
              </h2>
              <textarea
                name="organizationDescription"
                value={formData.organizationDescription}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Describe what your organization does"
                rows={4}
              />
            </div>
          )}

          {/* Step 2: Department Overview */}
          {step === 2 && (
            <div>
              <h2 className="text-xl font-semibold mb-4 text-center">
                What does your department do?
              </h2>
              <textarea
                name="departmentDescription"
                value={formData.departmentDescription}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Describe what your department does"
                rows={4}
              />
            </div>
          )}

          {/* Step 3: Predefined Parts */}
          {step === 3 && (
            <div>
              <h2 className="text-xl font-semibold mb-4 text-center">
                Describe Predefined Parts
              </h2>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Wheel</label>
                <textarea
                  name="wheel"
                  value={formData.parts.wheel}
                  onChange={handlePartChange}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Describe the wheel"
                  rows={2}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Door Panel</label>
                <textarea
                  name="doorPanel"
                  value={formData.parts.doorPanel}
                  onChange={handlePartChange}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Describe the door panel"
                  rows={2}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Seats</label>
                <textarea
                  name="seats"
                  value={formData.parts.seats}
                  onChange={handlePartChange}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Describe the seats"
                  rows={2}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Cockpit</label>
                <textarea
                  name="cockpit"
                  value={formData.parts.cockpit}
                  onChange={handlePartChange}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Describe the cockpit"
                  rows={2}
                />
              </div>
            </div>
          )}

          {/* Step 4: Important Acronyms */}
          {step === 4 && (
            <div>
              <h2 className="text-xl font-semibold mb-4 text-center">
                Important Acronyms in Your Organization
              </h2>
              <textarea
                name="acronyms"
                value={formData.acronyms}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="List important acronyms in your organization"
                rows={4}
              />
            </div>
          )}

          {/* Navigation Buttons and Step Indicator */}
          <div className="mt-6 flex justify-between items-center">
            {/* Previous Button */}
            {step > 1 && (
              <button
                type="button"
                onClick={handlePrevious}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400 transition"
              >
                Previous
              </button>
            )}

            {/* Step Indicator */}
            <p className="text-gray-600">
              Step {step} of {maxSteps}
            </p>

            {/* Next or Submit Button */}
            {step < maxSteps ? (
              <button
                type="button"
                onClick={handleNext}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
              >
                Next
              </button>
            ) : (
              <button
                type="submit"
                className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition"
              >
                Submit
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SetupWizard;
