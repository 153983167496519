import React from "react";
import { FiEdit, FiTrash, FiMessageSquare } from "react-icons/fi";

interface ConversationItemProps {
  id: string;
  text: string;
  onEdit: () => void;
  onDelete: () => void;
  onSelect: () => void; // New prop to handle selecting a conversation
  isSelected: boolean; // New prop to indicate if the conversation is selected
}

const ConversationItem: React.FC<ConversationItemProps> = ({
  text,
  onEdit,
  onDelete,
  onSelect,
  isSelected,
}) => {
  return (
    <div
      className={`flex items-center justify-between p-3 rounded-lg mb-2 cursor-pointer transition hover:bg-gray-100 ${
        isSelected ? "bg-gray-200" : "bg-white"
      }`}
      onClick={onSelect}
    >
      <div className="flex items-center cursor-pointer">
        <FiMessageSquare className="text-black mr-3" />
        <span className="text-gray-800">{text}</span>
      </div>
      <div className="flex space-x-2">
        <button
          onClick={onEdit}
          className="text-gray-700 hover:text-black transition"
          title="Edit"
        >
          <FiEdit />
        </button>
        <button
          onClick={onDelete}
          className="text-gray-700 hover:text-black transition"
          title="Delete"
        >
          <FiTrash />
        </button>
      </div>
    </div>
  );
};

export default ConversationItem;
