import React from "react";
interface ChatProps {
  message: String;
}
const ChatBubble: React.FC<ChatProps> = ({ message }) => {
  return (
    <div className="bg-white p-3 rounded-lg mb-4 w-full self-end">
      <p className="text-gray-700">{message}</p>
    </div>
  );
};

export default ChatBubble;
