import { login, signup } from "services/ApiService";
import User from "../models/User";

export const loginUser = async (username: string, password: string) => {
  try {
    const response = await login(username, password);
    const token = response.token;

    // Business logic (if any), like saving the token
    localStorage.setItem("token", token);

    return token;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

export const registerUser = async (userData: User) => {
  try {
    const response = await signup(userData);
    const token = response.token;

    // Business logic (if any), like saving the token
    localStorage.setItem("token", token);

    return token;
  } catch (error) {
    console.error("Registration error:", error);
    throw error;
  }
};

export const logoutUser = () => {
  localStorage.removeItem("token");
};
