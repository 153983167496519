import React, { useState } from "react";
import ChatBubble from "../components/ChatBubble";
import { FiSend } from "react-icons/fi";

interface ChatSectionProps {
  messages: { type: string; text: string }[];
  onSendMessage: (message: string) => void;
  sections: Array<"D3" | "D4" | "D5" | "D7">;
  handleCopyToSection: (
    messageText: string,
    section: "D3" | "D4" | "D5" | "D7"
  ) => void;
  creationDate: string | null;
}

const ChatSection: React.FC<ChatSectionProps> = ({
  messages,
  onSendMessage,
  sections,
  handleCopyToSection,
  creationDate,
}) => {
  const [userInput, setUserInput] = useState("");

  const handleSendMessage = () => {
    if (userInput.trim() === "") return;
    onSendMessage(userInput);
    setUserInput("");
  };

  return (
    <div className="flex-1 flex flex-col bg-gray-100">
      {creationDate && (
        <div className="p-4 bg-white text-gray-600 border-b">
          <p className="text-sm italic">
            Conversation started on {creationDate}
          </p>
        </div>
      )}
      <div className="flex-1 overflow-y-auto p-6">
        {messages.map((message, index) =>
          message.type === "user" ? (
            <ChatBubble key={index} message={message.text} />
          ) : (
            <div key={index} className="mb-4">
              <div className="flex items-center">
                <div className="bg-blue-500 text-white rounded-full h-5 w-5 flex items-center justify-center mr-3">
                  Q
                </div>
                <div className="text-gray-700 p-3 rounded-lg flex-1">
                  <p>{message.text}</p>
                </div>
              </div>
              {index !== 0 && (
                <div className="flex justify-between mt-2">
                  <div className="flex space-x-2 bg-white rounded-full">
                    {sections.map((section, index) => (
                      <button
                        key={index}
                        onClick={() =>
                          handleCopyToSection(message.text, section)
                        }
                        className="text-blue-500 hover:bg-blue-500 hover:text-white rounded-full px-3 py-1 transition"
                      >
                        {`Copy to ${section}`}
                      </button>
                    ))}
                  </div>
                  <button className="bg-black text-white px-4 py-2 rounded hover:bg-gray-700 transition">
                    For reference
                  </button>
                </div>
              )}
            </div>
          )
        )}
      </div>

      <div className="p-5 flex items-center">
        <div className="relative flex items-center w-full">
          <input
            type="text"
            className="w-full p-3 pr-12 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Type your message..."
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
          />
          <button
            onClick={handleSendMessage}
            title="Send"
            className="absolute right-2 bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition"
          >
            <FiSend size={24} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatSection;
