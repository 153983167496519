import React, { useEffect, useState } from "react";

const LoadingScreen = ({ duration = 2000 }) => {
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    // Set a timeout for when to start fading out
    const timer = setTimeout(() => {
      setFadeOut(true);
    }, duration);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [duration]);

  return (
    <div
      className={`flex justify-center items-center h-screen bg-white transition-opacity duration-500 ${
        fadeOut ? "opacity-0" : "opacity-100"
      }`}
    >
      <div className="text-center">
        <img
          src="https://via.placeholder.com/150"
          alt="Placeholder Logo"
          className="mb-4 mx-auto"
        />
        <h1 className="text-2xl font-semibold text-gray-700">
          Welcome to DeepQ
        </h1>
      </div>
    </div>
  );
};

export default LoadingScreen;
