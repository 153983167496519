import React from "react";
import { FiPlus } from "react-icons/fi";
import ConversationItem from "./ConversationItem";

interface Conversation {
  id: string;
  name: string;
}

interface SidebarProps {
  conversations: Conversation[];
  onAdd: () => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onSelect: (id: string) => void;
  currentConversationId: string | null;
}

const Sidebar: React.FC<SidebarProps> = ({
  conversations,
  onAdd,
  onEdit,
  onDelete,
  onSelect,
  currentConversationId,
}) => {
  return (
    <div className="w-1/4 bg-white p-4">
      <button
        onClick={onAdd}
        className="flex items-center w-lg bg-gray-200 text-black p-2 rounded-lg hover:bg-blue-600 transition mb-4"
      >
        <span className="mr-2">Add Conversation</span>
        <FiPlus className="text-white bg-black rounded-full w-6 h-6" />
      </button>

      <h2 className="text-xl font-semibold mb-4">Conversations</h2>

      <ul>
        {conversations.map((conversation) => (
          <ConversationItem
            key={conversation.id}
            id={conversation.id}
            text={conversation.name}
            onEdit={() => onEdit(conversation.id)}
            onDelete={() => onDelete(conversation.id)}
            onSelect={() => onSelect(conversation.id)} // Handle selecting a conversation
            isSelected={conversation.id === currentConversationId} // Determine if the conversation is selected
          />
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
