import axios from "axios";
import User from "models/User";

const apiClient = axios.create({
  baseURL: "https://api.example.com", // Your base URL
  headers: {
    "Content-Type": "application/json",
  },
});

// Enable or disable test mode
const testMode = true; // Set to true to enable test responses
const testDelay = 1000; // Delay in milliseconds for test mode

// Helper function to simulate API delay in test mode
const simulateDelay = () =>
  new Promise((resolve) => setTimeout(resolve, testDelay));

// Sign up a new user
export const signup = async (userData: User) => {
  if (testMode) {
    await simulateDelay();
    return { message: "User signed up successfully (Test Mode)" };
  }
  try {
    const response = await apiClient.post("/auth/signup", userData);
    return response.data;
  } catch (error) {
    console.error("Signup error:", error);
    throw error;
  }
};

// Log in an existing user
export const login = async (username: string, password: string) => {
  if (testMode) {
    await simulateDelay();
    return { token: "fake-jwt-token (Test Mode)" };
  }
  try {
    const response = await apiClient.post("/auth/login", {
      username,
      password,
    });
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

// Get user info
export const getUserInfo = async (token: string) => {
  if (testMode) {
    await simulateDelay();
    return { username: "testuser", email: "testuser@example.com" }; // Mock user info
  }
  try {
    const response = await apiClient.get("/users/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Get user info error:", error);
    throw error;
  }
};

// Update user info
export const updateUserInfo = async (token: string, userInfo: User) => {
  if (testMode) {
    await simulateDelay();
    return { message: "User info updated successfully (Test Mode)" };
  }
  try {
    const response = await apiClient.put("/users/me", userInfo, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Update user info error:", error);
    throw error;
  }
};

// Get admin prompt
export const getAdminPrompt = async (token: string) => {
  if (testMode) {
    await simulateDelay();
    return { prompt: "This is a test admin prompt (Test Mode)" };
  }
  try {
    const response = await apiClient.get("/admin/prompt", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Admin prompt info error:", error);
    throw error;
  }
};

// Simulate fetching a system response from the server
export const fetchSystemResponse = async (
  id: string,
  userMessage: string
): Promise<string> => {
  if (testMode) {
    await simulateDelay();
    return "This is a test response (Test Mode)";
  }
  try {
    const response = await apiClient.post("/chat/respond", {
      message: { id, userMessage },
    });
    return response.data.responseMessage;
  } catch (error) {
    console.error("Error fetching system response:", error);
    throw error;
  }
};

// Fetch all conversations for the logged-in user
export const fetchConversations = async (
  token: string
): Promise<{ id: string; name: string; createdAt: string }[]> => {
  if (testMode) {
    await simulateDelay();
    return [
      {
        id: "1",
        name: "Test Conversation 1",
        createdAt: "2024-01-01T12:00:00Z",
      },
      {
        id: "2",
        name: "Test Conversation 2",
        createdAt: "2024-01-02T12:00:00Z",
      },
    ]; // Mock conversations with creation dates
  }

  try {
    const response = await apiClient.get("/conversations", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.conversations; // Assuming the API returns { conversations: [{ id: '...', name: '...', createdAt: '...' }, ...] }
  } catch (error) {
    console.error("Error fetching conversations:", error);
    throw error;
  }
};

// Fetch messages for a specific conversation
export const fetchMessages = async (
  conversationId: string,
  token: string
): Promise<{
  messages: { text: string; type: string }[];
  createdAt: string;
}> => {
  if (testMode) {
    await simulateDelay();
    return {
      messages: [
        { text: "Hello! How can I assist you today?", type: "system" },
      ],
      createdAt: new Date().toISOString(),
    };
  }

  try {
    const response = await apiClient.get(
      `/conversations/${conversationId}/messages`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return {
      messages: response.data.messages, // Assuming the API returns { messages: [{ text: '...', type: 'user' }, ...] }
      createdAt: response.data.createdAt, // Assuming the API returns createdAt for the conversation
    };
  } catch (error) {
    console.error("Error fetching messages:", error);
    throw error;
  }
};

// Add a new conversation
export const addConversation = async (
  token: string
): Promise<{ id: string; name: string; createdAt: string }> => {
  if (testMode) {
    await simulateDelay();
    const newConversation = {
      id: `${Date.now()}`,
      name: `New Conversation ${Date.now()}`,
      createdAt: new Date().toISOString(),
    };
    return newConversation;
  }

  try {
    const response = await apiClient.post(
      "/conversations",
      { name: `New Conversation ${Date.now()}` }, // Example payload
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.conversation; // Assuming the API returns { conversation: { id: '...', name: '...', createdAt: '...' } }
  } catch (error) {
    console.error("Error adding conversation:", error);
    throw error;
  }
};

// Delete a conversation
export const deleteConversation = async (
  conversationId: string,
  token: string
): Promise<void> => {
  if (testMode) {
    await simulateDelay();
    return;
  }

  try {
    await apiClient.delete(`/conversations/${conversationId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Error deleting conversation:", error);
    throw error;
  }
};
