import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../context/AuthContext";
import LoadingScreen from "../components/LoadingScreen";
import Sidebar from "../components/Sidebar";
import RightSidebar from "../components/RightSidebar";
import ChatSection from "../components/ChatSection";
import {
  fetchSystemResponse,
  fetchConversations,
  fetchMessages,
  addConversation,
  deleteConversation,
} from "services/ApiService";
import { FiSettings } from "react-icons/fi";
import Spinner from "components/Spinner";
import SettingsModal from "components/SettingsModal";

const Index: React.FC = () => {
  const sections: Array<"D3" | "D4" | "D5" | "D7"> = ["D3", "D4", "D5", "D7"];
  const { logout, token } = useAuth();
  //const navigate = useNavigate();

  // State management
  const [loading, setLoading] = useState(true);
  const [selectingConversation, setSelectingConversation] = useState(false);
  const [conversations, setConversations] = useState<
    { id: string; name: string; createdAt: string }[]
  >([]);
  const [currentConversationId, setCurrentConversationId] = useState<
    string | null
  >("1");
  const [currentConversationDate, setCurrentConversationDate] = useState<
    string | null
  >(null);
  const [messages, setMessages] = useState<{ type: string; text: string }[]>([
    { type: "system", text: "Hello! How can I assist you today?" },
  ]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userInfo, setUserInfo] = useState({
    username: "",
    email: "",
    name: "",
    password: "",
  });
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [D3Content, setD3Content] = useState("");
  const [D4Content, setD4Content] = useState("");
  const [D5Content, setD5Content] = useState("");
  const [D7Content, setD7Content] = useState("");

  // Initial load of conversations
  useEffect(() => {
    const loadConversations = async () => {
      try {
        if (token) {
          const data = await fetchConversations(token);
          setConversations(data);
        }
      } catch (error) {
        console.error("Error loading conversations:", error);
      } finally {
        setLoading(false);
      }
    };

    loadConversations();
  }, [token]);
  // Handle adding a new conversation
  const handleAddConversation = useCallback(async () => {
    try {
      setSelectingConversation(true);
      if (token) {
        const newConversation = await addConversation(token);
        setConversations((prev) => [...prev, newConversation]);
        setCurrentConversationId(newConversation.id);
        setCurrentConversationDate(newConversation.createdAt);
        setMessages([
          { type: "system", text: "Welcome to your new conversation!" },
        ]);
      }
    } catch (error) {
      console.error("Error adding conversation:", error);
    } finally {
      setSelectingConversation(false); // Start loading spinner
    }
  }, [token]);

  // Handle deleting a conversation
  const handleDeleteConversation = useCallback(
    async (id: string) => {
      try {
        setSelectingConversation(true);
        if (token) {
          await deleteConversation(id, token);
          setConversations((prev) =>
            prev.filter((conversation) => conversation.id !== id)
          );

          if (currentConversationId === id) {
            if (conversations.length > 0) {
              const firstConversation = conversations[0];
              setCurrentConversationId(firstConversation.id);
              setCurrentConversationDate(firstConversation.createdAt);
              const { messages } = await fetchMessages(
                firstConversation.id,
                token
              );
              setMessages(messages);
            } else {
              setCurrentConversationId(null);
              setCurrentConversationDate(null);
              setMessages([]);
            }
          }
        }
      } catch (error) {
        console.error("Error deleting conversation:", error);
      } finally {
        setSelectingConversation(false); // Start loading spinner
      }
    },
    [token, currentConversationId, conversations]
  );
  // Handle message sending and response fetching
  const handleSendMessage = useCallback(
    async (userInput: string) => {
      if (userInput.trim() === "") return;

      const newMessage = { type: "user", text: userInput };
      setMessages((prevMessages) => [...prevMessages, newMessage]);

      try {
        if (currentConversationId) {
          const systemResponse = await fetchSystemResponse(
            currentConversationId,
            userInput
          );
          setMessages((prevMessages) => [
            ...prevMessages,
            { type: "system", text: systemResponse },
          ]);
        }
      } catch (error) {
        console.error("Failed to fetch system response:", error);
      }
    },
    [currentConversationId]
  );

  // Handle selecting a conversation
  const handleSelectConversation = useCallback(
    async (id: string) => {
      // If the selected conversation is already the current one, do nothing
      if (currentConversationId === id) return;

      setSelectingConversation(true); // Start loading spinner
      try {
        if (token) {
          const { messages, createdAt } = await fetchMessages(id, token);
          setMessages(messages);
          setCurrentConversationId(id);
          setCurrentConversationDate(createdAt);
        }
      } catch (error) {
        console.error("Error loading messages:", error);
      } finally {
        setSelectingConversation(false); // Start loading spinner
      }
    },
    [token, currentConversationId]
  );

  // Handle copying to sections
  const handleCopyToSection = (
    messageText: string,
    section: "D3" | "D4" | "D5" | "D7"
  ) => {
    if (section === "D3") setD3Content(messageText);
    if (section === "D4") setD4Content(messageText);
    if (section === "D5") setD5Content(messageText);
    if (section === "D7") setD7Content(messageText);
  };

  const handleSave = useCallback(() => {
    console.log("Saving user info...", userInfo);
    setSaveSuccess(true);
    setTimeout(() => {
      setIsModalVisible(false);
      setSaveSuccess(false);
    }, 1000);
  }, [userInfo]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  if (loading) {
    return <LoadingScreen duration={1000} />;
  }

  return (
    <div className="h-screen flex flex-col">
      <div className="flex justify-between items-center p-4 bg-white text-blue-600 border-b border-gray-100">
        <h1 className="text-2xl font-semibold">DeepQ</h1>
        <div className="flex space-x-4">
          <button
            onClick={() => setIsModalVisible(true)}
            className="flex items-center bg-gray-200 text-black px-4 py-2 rounded-lg hover:bg-blue-600 transition"
            title="Settings"
          >
            <span className="mr-2">Settings</span>
            <FiSettings className="text-gray-700" />
          </button>
        </div>
      </div>

      <div className="flex-1 flex">
        <Sidebar
          conversations={conversations}
          onAdd={handleAddConversation}
          onEdit={(id) => console.log(`Edit conversation ${id}`)}
          onDelete={handleDeleteConversation}
          onSelect={handleSelectConversation}
          currentConversationId={currentConversationId}
        />

        <ChatSection
          messages={messages}
          onSendMessage={handleSendMessage}
          sections={sections}
          handleCopyToSection={handleCopyToSection}
          creationDate={currentConversationDate} // Pass the creation date to ChatSection
        />

        <RightSidebar
          D3Content={D3Content}
          D4Content={D4Content}
          D5Content={D5Content}
          D7Content={D7Content}
        />
      </div>

      <SettingsModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        userInfo={userInfo}
        onInputChange={handleInputChange}
        onSave={handleSave}
        saveSuccess={saveSuccess}
        token={token} // Pass token or userId as needed
        logout={logout}
      />
      {/* Show loading spinner during conversation selection */}
      {selectingConversation && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default Index;
