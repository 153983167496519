import React, { useState } from "react";
import { FiArrowLeft } from "react-icons/fi";
import Modal from "./Modal";

interface SettingsModalProps {
  isVisible: boolean;
  onClose: () => void;
  userInfo: {
    username: string;
    email: string;
    name: string;
    password: string;
  };
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSave: () => void;
  saveSuccess: boolean;
  token: string | null; // Or userId depending on your structure
  logout: () => void;
}

const SettingsModal: React.FC<SettingsModalProps> = ({
  isVisible,
  onClose,
  userInfo,
  onInputChange,
  onSave,
  saveSuccess,
  token,
  logout,
}) => {
  const [currentView, setCurrentView] = useState<
    "menu" | "userInfo" | "adminPrompt" | "userPrompt"
  >("menu");

  const renderMenu = () => (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold mb-4">Settings Menu</h2>
      <button
        onClick={() => setCurrentView("userInfo")}
        className="w-full bg-gray-200 text-black p-2 rounded-lg hover:bg-blue-600 transition"
      >
        User Information Settings
      </button>
      <button
        onClick={() => setCurrentView("adminPrompt")}
        className="w-full bg-gray-200 text-black p-2 rounded-lg hover:bg-blue-600 transition"
      >
        Admin Prompt
      </button>
      <button
        onClick={() => setCurrentView("userPrompt")}
        className="w-full bg-gray-200 text-black p-2 rounded-lg hover:bg-blue-600 transition"
      >
        User Prompt
      </button>
      <button
        onClick={() => {
          onClose();
          logout(); // Assuming logout is handled here
        }}
        className="w-full bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition"
      >
        Logout
      </button>
    </div>
  );

  const renderUserInfo = () => (
    <div>
      <button
        onClick={() => setCurrentView("menu")}
        className="mb-4 flex items-center text-blue-600 hover:text-blue-800 transition"
      >
        <FiArrowLeft className="mr-2" />
        Back
      </button>
      <h2 className="text-xl font-semibold mb-4">User Information</h2>
      <form className="space-y-4">
        <div>
          <label className="block text-gray-700">Username</label>
          <input
            type="text"
            name="username"
            value={userInfo.username}
            onChange={onInputChange}
            placeholder="Your Username"
            className="w-full p-2 border border-gray-300 rounded-lg"
          />
        </div>
        <div>
          <label className="block text-gray-700">Email</label>
          <input
            type="email"
            name="email"
            value={userInfo.email}
            onChange={onInputChange}
            placeholder="Your Email"
            className="w-full p-2 border border-gray-300 rounded-lg"
          />
        </div>
        <div>
          <label className="block text-gray-700">Name</label>
          <input
            type="text"
            name="name"
            value={userInfo.name}
            onChange={onInputChange}
            placeholder="Your Name"
            className="w-full p-2 border border-gray-300 rounded-lg"
          />
        </div>
        <div>
          <label className="block text-gray-700">Password</label>
          <input
            type="password"
            name="password"
            value={userInfo.password}
            onChange={onInputChange}
            placeholder="New Password"
            className="w-full p-2 border border-gray-300 rounded-lg"
          />
        </div>
        <button
          type="button"
          onClick={onSave}
          className="w-full bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition"
        >
          Save
        </button>
        {saveSuccess && (
          <p className="text-green-500 mt-2">
            User information saved successfully!
          </p>
        )}
      </form>
    </div>
  );

  const renderAdminPrompt = () => (
    <div>
      <button
        onClick={() => setCurrentView("menu")}
        className="mb-4 flex items-center text-blue-600 hover:text-blue-800 transition"
      >
        <FiArrowLeft className="mr-2" />
        Back
      </button>
      <h2 className="text-xl font-semibold mb-4">Admin Prompt</h2>
      <p>Admin Prompt settings go here.</p>
    </div>
  );

  const renderUserPrompt = () => (
    <div>
      <button
        onClick={() => setCurrentView("menu")}
        className="mb-4 flex items-center text-blue-600 hover:text-blue-800 transition"
      >
        <FiArrowLeft className="mr-2" />
        Back
      </button>
      <h2 className="text-xl font-semibold mb-4">User Prompt</h2>
      <p>User Prompt settings go here.</p>
    </div>
  );

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      {currentView === "menu" && renderMenu()}
      {currentView === "userInfo" && renderUserInfo()}
      {currentView === "adminPrompt" && renderAdminPrompt()}
      {currentView === "userPrompt" && renderUserPrompt()}
    </Modal>
  );
};

export default SettingsModal;
