import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from "react";
import { loginUser, registerUser, logoutUser } from "services/AuthService";

// Define the types for the AuthContext
interface AuthContextType {
  isAuthenticated: boolean;
  loading: boolean;
  token: string | null;
  login: (username: string, password: string) => Promise<boolean>;
  register: (userData: any) => Promise<boolean>; // Adjust `any` to the specific type of `userData` if you have it.
  logout: () => void;
}

// Create the AuthContext with the correct type, or undefined initially
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Hook to use the AuthContext
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

// Define props for AuthProvider
interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string | null>(null); // Add state for token

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
      setIsAuthenticated(true);
    }
    setLoading(false);
  }, []);

  const login = async (
    username: string,
    password: string
  ): Promise<boolean> => {
    try {
      const token = await loginUser(username, password);
      if (token) {
        setToken(token); // Store the token in state
        localStorage.setItem("token", token); // Save token to localStorage
        setIsAuthenticated(true);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Login error:", error);
      return false;
    }
  };

  const register = async (userData: any): Promise<boolean> => {
    try {
      const token = await registerUser(userData);
      if (token) {
        setToken(token); // Store the token in state
        localStorage.setItem("token", token); // Save token to localStorage
        setIsAuthenticated(true);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Registration error:", error);
      return false;
    }
  };

  const logout = () => {
    logoutUser(); // Call logout from authService
    setToken(null); // Clear the token from state
    localStorage.removeItem("token"); // Remove token from localStorage
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, loading, token, login, register, logout }} // Include token in the context value
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};
